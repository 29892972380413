<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 's']" @shortkey="submit(1)" @click="submit(1)" class="md-raised md-primary"><span>G</span>ửi phê duyệt<md-tooltip>Gửi yêu cầu phê duyệt (Ctrl + L)</md-tooltip></md-button>
                        <md-button :disabled="entity.status > 0" v-shortkey="['ctrl', 'l']" @shortkey="submit(0)" @click="submit(0)" class="md-raised md-temp"><span>L</span>ưu tạm thời<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.invoiceCode.$error }">
                                <label for="invoiceCode">Số quyết toán</label>
                                <md-input disabled name="invoiceCode" v-model="entity.invoiceCode"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.invoiceCode.required">Vui lòng nhập số quyết toán</span>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                    <label>Người quyết toán</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                    <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn người quyết toán</span>
                                </md-autocomplete>
                                <md-button @click="openStaff()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm nhân viên</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-datepicker v-model="entity.invoiceDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.invoiceDate.$error }">
                                <label>Ngày lập</label>
                                <span class="md-error" v-if="submitted && !$v.entity.invoiceDate.required">Vui lòng chọn ngày quyết toán</span>
                            </md-datepicker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-8 m-8 c-12">
                            <md-field>
                               <label for="note">Lý do</label>
                               <md-input name="note" v-model="entity.note"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div style="display:flex;">
                                <md-field>
                                    <label for="note">Tổng quyết toán</label>
                                    <md-input name="pay" :value="formatNumber(entity.amount)" ></md-input>
                                </md-field>
                            </div>
                        </div>
                    </div>
                    <div class="form-body form-tab" style="padding: 0;">
                        <div class="row">
                            <div class="col l-12 m-12 c-12">
                                <md-tabs>
                                    <md-tab id="tab-estimate" class="tab-content" md-label="Tạm ứng">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="tool-bar">
                                                        <md-button @click="addRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                    </div>
                                                    <table class="data-table-2"> 
                                                        <thead> 
                                                            <tr> 
                                                                <th style="width:100px;">#</th>
                                                                <th class="left" style="width:30%;">Số tạm ứng</th> 
                                                                <th class="left" style="width:25%;">Số dự toán</th>
                                                                <th class="right" style="width:15%;">Tiền dự toán</th>
                                                                <th class="right" style="width:15%;">Tiền tạm ứng</th>
                                                                <th style="width:10%">Hành động</th> 
                                                            </tr> 
                                                        </thead> 
                                                        <tbody v-if="loadding == true" style="height: 100px;"> 
                                                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                        </tbody>
                                                        <tbody v-if="loadding == false"> 
                                                            <tr v-for="(item, index) in entity.advancePaymentList" :key="'request-' + item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                <td class="center">{{index + 1}}</td>
                                                                <td class="center">
                                                                    <div class="grid-control">
                                                                        <input v-model="item.advancePayment.advancePaymentCode" class="form-control" type="text">
                                                                        <md-button @click="openAdvancePaymentList(index)" class="md-icon-button">
                                                                            <md-icon>manage_search</md-icon>
                                                                            <md-tooltip>Tìm số tạm ứng</md-tooltip>
                                                                        </md-button>
                                                                    </div>
                                                                </td>
                                                                <td class="left">
                                                                    {{item.estimate.estimateCode}}
                                                                </td>
                                                                <td class="right">
                                                                    {{ formatNumber(item.estimate.amount) }}
                                                                </td>
                                                                <td class="right">
                                                                    {{ formatNumber(item.advancePayment.amount) }}
                                                                </td>
                                                                <td class="grid-action">
                                                                    <a @click="delRow(index, item.estimateId)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                </td>
                                                            </tr> 
                                                        </tbody> 
                                                    </table> 
                                                </div>
                                            </div>
                                        </div>
                                    </md-tab>
                                    <md-tab id="tab-supplies" class="tab-content" md-label="Chi tiết">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="tool-bar">
                                                        
                                                    </div>
                                                    <table class="data-table-2"> 
                                                        <thead> 
                                                            <tr> 
                                                                <th rowspan="2" style="width:100px;">#</th>
                                                                <th rowspan="2" style="width:130px;">Phương tiện</th> 
                                                                <th rowspan="2" style="width:12%;">Vật tư</th> 
                                                                <th rowspan="2" style="width:110px;">Nguồn</th>
                                                                <th rowspan="2" style="width:120px;">ĐVT</th> 
                                                                <th rowspan="2" style="width:100px;">Số lượng</th>
                                                                <th colspan="3" style="width:450px; background-color: #B4E380;">Dự toán</th>
                                                                <th rowspan="2" style="width:150px; background-color: #FFD18E;">Tạm ứng</th>
                                                                <th colspan="3" style="width:450px; background-color: #7ED4AD;">Quyết toán</th>
                                                            </tr>
                                                            <tr> 
                                                                <th class="right" style="width:150px; background-color: #B4E380;">Số tiền</th>
                                                                <th class="right" style="width:150px; background-color: #B4E380;">VAT</th>
                                                                <th class="right" style="width:150px; background-color: #B4E380;">Thành tiền</th>
                                                                <th class="right" style="width:150px; background-color: #7ED4AD;">Số tiền</th>
                                                                <th class="right" style="width:150px; background-color: #7ED4AD;">VAT</th>
                                                                <th class="right" style="width:150px; background-color: #7ED4AD;">Thành tiền</th>
                                                            </tr> 
                                                        </thead> 
                                                        <tbody v-if="loadding == true" style="height: 100px;"> 
                                                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                        </tbody>
                                                        <tbody v-if="loadding == false"> 
                                                            <tr v-for="(item, index) in entity.details" :key="'detail-' + item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                <td class="center">{{index + 1}}</td>
                                                                <td class="center">
                                                                    <div>{{ item.vehicle.licensePlate }}</div>
                                                                </td>
                                                                <td class="left">
                                                                    <div>{{ item.title }}</div>
                                                                </td>
                                                                <td class="center">
                                                                    <div>{{ item.sourceType.name }}</div>
                                                                </td> 
                                                                <td class="center">
                                                                    <div>{{ item.unit }}</div>
                                                                </td> 
                                                                <td class="center">
                                                                    {{formatNumber(item.quantity)}}
                                                                </td> 
                                                                <td class="number-right">
                                                                    <div>{{formatNumber(item.price)}}</div>
                                                                </td> 
                                                                <td class="number-right">
                                                                    {{formatNumber(item.vat) + '%'}}
                                                                </td>
                                                                <td class="number-right">
                                                                    {{formatNumber(item.amount)}}
                                                                </td>
                                                                <td class="number-right">
                                                                    {{formatNumber(item.advancePaymentAmount)}}
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <number @change="changeAmount(index)" v-model="item.invoicePrice" v-bind="currency" class="form-control currency"></number> 
                                                                    </div>
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <number @change="changeAmount(index)" v-model="item.invoiceVat" v-bind="vatF" class="form-control currency"></number> 
                                                                    </div>
                                                                </td> 
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <number disabled v-model="item.invoiceAmount" v-bind="currency" class="form-control currency"></number>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody> 
                                                    </table> 
                                                </div>
                                            </div>
                                        </div>
                                    </md-tab>
                                    <md-tab id="tab-approve" class="tab-content" md-label="Phê duyệt">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="tool-bar">
                                                        <md-button @click="addApproveRow()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                                    </div>
                                                    <table class="data-table-2"> 
                                                        <thead> 
                                                            <tr> 
                                                                <th style="width:100px;">#</th> 
                                                                <th style="width:25%;">Bộ phận/phòng</th> 
                                                                <th style="width:25%;">Người phê duyệt</th> 
                                                                <th style="width:25%;">Tiêu đề</th>
                                                                <th style="width:10%;">Thứ tự</th> 
                                                                <th style="width:150px">Hành động</th> 
                                                            </tr> 
                                                        </thead> 
                                                        <tbody v-if="loadding == true" style="height: 100px;"> 
                                                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                        </tbody>
                                                        <tbody v-if="loadding == false"> 
                                                            <tr v-for="(item, index) in entity.approveList" :key="'approve-' + item.id" :class="{ 'odd': index % 2 !== 0 }"> 
                                                                <td class="center">{{index + 1}}</td>
                                                                <td class="center">
                                                                    <div class="grid-control">
                                                                        <input disabled v-model="item.dept.name" :class="{'is-error': $v.entity.approveList.$each[index].deptId.$error}"  class="form-control" type="text">
                                                                        <md-button @click="openDept(item.id)" class="md-icon-button">
                                                                            <md-icon>manage_search</md-icon>
                                                                            <md-tooltip>Tìm bộ phận</md-tooltip>
                                                                        </md-button>
                                                                    </div>
                                                                </td> 
                                                                <td class="center">
                                                                    <div class="grid-control">
                                                                        <input disabled v-model="item.staff.fullName" :class="{'is-error': $v.entity.approveList.$each[index].staffId.$error}"  class="form-control" type="text">
                                                                        <md-button @click="openApprove(item.id)" class="md-icon-button">
                                                                            <md-icon>manage_search</md-icon>
                                                                            <md-tooltip>Tìm nhân viên</md-tooltip>
                                                                        </md-button>
                                                                    </div>
                                                                </td> 
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <input v-model="item.title" class="form-control" type="text">
                                                                    </div>
                                                                </td>
                                                                <td class="center">
                                                                    <div class="group-control">
                                                                        <input v-model="item.orderSort" class="form-control" type="number" style="text-align: center;">
                                                                    </div>
                                                                </td> 
                                                                <td class="grid-action">
                                                                    <a @click="delApproveRow(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                                </td> 
                                                            </tr> 
                                                        </tbody> 
                                                    </table> 
                                                </div>
                                            </div>
                                        </div>
                                    </md-tab>
                                </md-tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <staffList ref="staffList" @close="closeStaff"/>
        <approveList ref="approveList" @close="closeApprove"/>
        <deptList ref="deptList" @close="closeDept"/>
        <advancePaymentList ref="advancePaymentList" @close="closeAdvancePayment"/>
   </div>
</template>
<script>
    import repairInvoiceService from '../../../api/repairInvoiceService';
    import repairEstimateService from '../../../api/repairEstimateService';
    import repairAdvancePaymentService from '../../../api/repairAdvancePaymentService';
    import staffService from '../../../api/staffService';
    import approveSettingService from '../../../api/approveSettingService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import common from '../../../mixins'; 
    import staffList from '../../../components/popup/_StaffList.vue';
    import approveList from '../../../components/popup/_StaffList.vue';
    import deptList from '../../../components/popup/_DepartmentList.vue';
    import advancePaymentList from '../../../components/popup/_RepairAdvancePaymentList.vue';

    export default ({
        components: {
            staffList,
            deptList,
            approveList,
            advancePaymentList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật quyết toán'
        },
        data() {
            return {
                title: '',
                id: 0,
                submitted: false,
                loadding: false,
                entity: { id: 0, files: [], approveList: [], advancePaymentList: [], details: [], deptId: 0, staffId: '', staff: {}, invoiceCode: '', invoiceDate: null, status: 0, amount: 0 },
                staffName: '',
                staffs: [],
                approveTypes: common.approveTypes,
                approve: { id: 'id_' + common.getFakeId(), status: 1, isCommenter: false, dept: { code: '', name: '' }, deptId: 0, staff: { id: 0, fullName: '' }, staffId: 0, title: 'Người đề nghị', note: '', orderSort: 1, objectType: 4},
                estimateName: '',
                estimates: [],
                numberF: common.numberF,
                currency: common.currencyF,
                vatF: common.vatF,
                advancePayment: { id: 'id_' + common.getFakeId(), estimate: {estimateCode: '', amount: 0}, advancePaymentId: null, estimateId: 0, advancePayment: { advancePaymentCode: '', amount: 0}},
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật quyết toán';
               this.getById();
            }
            else{
               this.title = 'Thêm mới quyết toán';
               this.entity.invoiceDate = common.dateNow;
               this.getByAccount();
               this.entity.approveList.push(this.approve);
               this.entity.advancePaymentList.push(this.advancePayment);
               this.getApproveSetting();
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            addRow(){
                const item = { id: 'id_' + common.getFakeId(), estimate: {estimateCode: '', amount: 0}, advancePaymentId: null, estimateId: 0, advancePayment: { advancePaymentCode: '', amount: 0}};
                this.entity.advancePaymentList.push(item);
            },

            delRow(index, estimateId){
                this.entity.details = this.entity.details.filter(x => x.estimateId != estimateId);
                this.entity.advancePaymentList.splice(index, 1);
                this.calSummary();
            },

            closeAdvancePayment(item){
                this.entity.advancePaymentList.splice(this.selectedId, 1);

                if(item.type == 1){
                    this.getAdvancePaymentById(item.id);
                }
                else{
                    let obj = {
                        estimate: { estimateCode: item.advancePaymentCode, amount: item.amount },
                        advancePaymentId: null,
                        estimateId: item.id,
                        advancePayment: { advancePaymentCode: item.advancePaymentCode, amount: 0 }
                    }
                    this.entity.advancePaymentList.push(obj);
                    this.getEstimateById(item.id);
                }
            },

            openAdvancePaymentList(id){
                this.selectedId = id;
                this.$refs.advancePaymentList.open();
            },

            getEstimateById(id){
                repairEstimateService.getById(id).then((response) => {
                    if(response.statusCode == 200){
                        for(var i = 0; i < response.data.details.length; i ++){
                            const detail = response.data.details[i];
                            detail.invoicePrice = detail.price;
                            detail.invoiceVat = detail.vat;
                            detail.invoiceAmount = detail.amount;
                            let obj = Object.assign({}, detail, { advancePaymentId: id });
                            this.entity.details.push(obj);
                        }
                        this.calSummary();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); this.$refs.advancePaymentList.close();});
            },

            getAdvancePaymentById(id){
                this.setLoading(true);
                repairAdvancePaymentService.getById(id).then((response) => {
                    if(response.statusCode == 200){
                        for(var i = 0; i < response.data.details.length; i ++){
                            const detail = response.data.details[i];
                            detail.invoicePrice = detail.price;
                            detail.invoiceVat = detail.vat;
                            detail.invoiceAmount = detail.amount;
                            let obj = Object.assign({}, detail, { advancePaymentId: id });
                            this.entity.details.push(obj);
                        }

                        for(var j = 0; j < response.data.estimateList.length; j ++){
                            let item = response.data.estimateList[j];
                            item.estimate.amount = item.estimateAmount;
                            item.advancePayment = { id: response.data.id, amount: item.advancePaymentAmount, advancePaymentCode: response.data.advancePaymentCode };
                            this.entity.advancePaymentList.push(item);
                        }
                        this.calSummary();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); this.$refs.advancePaymentList.close();});
            },

            calSummary(){
                this.entity.amount = 0;
                for (var i = 0; i < this.entity.details.length; i++) {
                    this.entity.amount = parseFloat(this.entity.amount) + parseFloat(this.entity.details[i].invoiceAmount);
                }
            },

            //Change quantity
            changeAmount(index){
                let vat = 0;
                if(parseFloat(this.entity.details[index].invoiceVat) > 0){
                    vat = (this.entity.details[index].quantity * this.entity.details[index].invoicePrice) * (parseFloat(this.entity.details[index].invoiceVat) / 100);
                }
                this.entity.details[index].invoiceAmount = vat + (this.entity.details[index].quantity * this.entity.details[index].invoicePrice);
                
                this.calSummary();
            },

            updateFile(files){
                this.entity.files = files;
            },

            getCode(staffId){
                this.setLoading(true);
                repairInvoiceService.getCode(staffId).then((response) => {
                    if(response.statusCode == 200){
                        this.entity.invoiceCode = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            //Get Approve Setting
            getApproveSetting(){
                approveSettingService.getSetting(common.approveType.requestRepair).then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null && response.data.length > 0)
                        {
                            for (let i = 0; i < response.data.length; i++) {
                                let setting = response.data[i];
                                let approve = { id: 'id_' + common.getFakeId() + i, status: 1, dept: { code: '', name: '' }, staff: { id: 0, code: '', fullName: '' }, deptId: 0, staffId: 0, title: '', note: '', orderSort: 1, typeCode: String(common.approveType.requestRepair)};
                                approve.id = 'id_' + common.getFakeId() + i;
                                approve.staffId = setting.staffId;
                                approve.deptId = setting.deptId;
                                approve.dept.code = setting.department.code;
                                approve.dept.name = setting.department.name;
                                approve.staff.code = setting.staff.code;
                                approve.staff.fullName = setting.staff.fullName;
                                approve.title = setting.title;
                                approve.orderSort = setting.orderSort + 1;
                                
                                this.entity.approveList.push(approve);
                            }
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //APPROVE
            orderApprove(){
                if(this.entity.approveList != null){
                    for(let i = 0; i < this.entity.approveList.length; i ++){
                        this.entity.approveList[i].orderSort = i + 1;
                    }
                }
            },

            delApproveRow(item){
                const index = this.entity.approveList.findIndex(x => x.id == item.id);
                this.entity.approveList.splice(index, 1);
                this.orderApprove();
            },

            addApproveRow(){
                let order = 1;
                if(this.entity.approveList != null){
                    order = this.entity.approveList.length + 1;
                }
                let item = { id: 'id_' + common.getFakeId(), status: 1, isCommenter: false, dept: { code: '', name: '' }, staff: { id: 0, fullName: '' }, deptId: 0, staffId: 0, title: '', orderSort: order, objectType: 4 };
                this.entity.approveList.push(item);
            },

            
            closeApprove(item){
                const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
                this.entity.approveList[selected].staff = item;
                this.entity.approveList[selected].staffId = item.id;
                this.$refs.approveList.close();
                this.selectedId = '';
            },

            openApprove(id){
                this.selectedId = id;
                const index = this.entity.approveList.findIndex(x => x.id == id);
                this.deptId = this.entity.approveList[index].deptId;
                this.$refs.approveList.open(this.deptId);
            },

            closeDept(item){
                const selected = this.entity.approveList.findIndex(x => x.id == this.selectedId);
                this.entity.approveList[selected].dept = item;
                this.entity.approveList[selected].title = item.name;
                this.entity.approveList[selected].deptId = item.id;
                if(item.managerId > 0){
                    this.entity.approveList[selected].staff = item.manager;
                    this.entity.approveList[selected].staffId = item.managerId;
                }
                this.$refs.deptList.close();
                this.selectedId = '';
            },

            openDept(id){
                this.selectedId = id;
                this.$refs.deptList.open();
            },

            //Staff
            closeStaff(item){
                this.staffName = item.fullName;
                this.entity.staffId = item.id;
                this.entity.deptId = item.departmentId;
                this.entity.approveList[0].staffId = item.id;
                this.entity.approveList[0].staff = item;
                this.entity.approveList[0].deptId = item.department.id;
                this.entity.approveList[0].dept = item.department;
                this.getCode(item.id);
                this.$refs.staffList.close();
            },

            getStaffSelected(val){
                this.entity.staffId = val.id;
                this.entity.deptId = val.departmentId;
                this.staffName = val.fullName;
                this.getCode(val.id);
                this.entity.approveList[0].staffId = val.id;
                this.entity.approveList[0].staff = val;
                this.entity.approveList[0].deptId = val.department.id;
                this.entity.approveList[0].dept = val.department;
            },

            openStaff(){
                this.$refs.staffList.open();
            },

            getStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.staffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(status){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                
                if(this.entity.details.length > 0){
                    this.entity.details = this.entity.details.filter(item => !(item.vehicleId == 0));
                    this.entity.details.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.details.length == 0){
                    messageBox.showWarning("Vui lòng chọn tạm ứng/dự toán");
                    return;
                }
                if(this.entity.approveList.length > 0){
                    this.entity.approveList = this.entity.approveList.filter(item => !(item.deptId == 0));
                    this.entity.approveList.forEach(function(item, index, self) {
                        if(!common.isNumeric(self[index].id)){
                            self[index].id = 0;
                        }
                    });
                }
                if(this.entity.approveList.length <= 1){
                    messageBox.showWarning("Vui lòng chọn thêm phê duyệt");
                    return;
                }
                this.entity.status = status;
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                repairInvoiceService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                        this.$router.push('/repair-invoice');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                repairInvoiceService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/repair-invoice');
                    }
                    else{
                        this.entity.status = 1;
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                repairInvoiceService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                        this.staffName = this.entity.staff.fullName;
                        this.estimateName = this.entity.estimate.estimateCode;
                        this.$refs.upload.loadData(this.entity.files);
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.entity.staff = response.data;
                            this.entity.staffId = response.data.id;
                            this.entity.deptId = response.data.departmentId;
                            this.staffName = response.data.fullName;
                            this.currentUser = response.data;
                            this.approve.staffId = response.data.id;
                            this.approve.staff = response.data;
                            this.approve.deptId = response.data.department.id;
                            this.approve.dept = response.data.department;
                            this.getCode(this.entity.staffId);
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            back(){
                this.$router.push('/repair-invoice');
            }
        },
        watch: { 
            staffName: function (val) { 
                if(val == ''){
                    this.entity.staffId = 0;
                }
            },
        },
        validations: {
            entity: {
                staffId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                deptId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                invoiceCode: { required },
                invoiceDate: { required },
                approveList: {
                    $each: {
                        deptId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                        staffId: {
                            required,
                            isSelected(value) {
                                if (value > 0) {
                                    return true;
                                }
                                else{
                                    return false;
                                }
                            }
                        },
                    }
                },
            }
        }
     })

</script>

<style scoped>
    .approve{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .approve .md-chip{
        height: 25px !important;
        line-height: 25px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
    }

    .approve .md-chip i {
        font-size: 20px;
        padding-right: 5px;
    }
</style>
